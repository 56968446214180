import React from 'react'
import Header from '../components/global/header/header'
import Footer from '../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'
import moment from 'moment'
import { Link } from '../i18n'

import _ from 'lodash';
import { IntlProvider,FormattedMessage } from 'react-intl'



class SingleCaseStudy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            applyOpen:false,
        };

    }

    toggleContact = () => {
        this.setState({
            contactOpen: !this.state.contactOpen
        });

        document.body.classList.toggle('noscroll');
    }

    componentDidMount() {
        if(document.body.classList.contains('noscroll')) {
            document.body.classList.remove('noscroll');
        }
    }

    
    render() {
    
        const {article, section} = this.props;

        if(!article) return null;

        const backLink = `/${section}`;
        const date = article.date 
                ? moment(article.date).calendar() 
                : '';
        
        return (
            <PageTransition
                defaultStyle={{
                    transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
                    position: 'relative',
                    width: '100%',
                    opacity: 0,
                    maxWidth: '2000px'
                }}

                transitionStyles={{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                    exiting: { opacity: 0, }
                }}
                transitionTime={1250}
                >
                <div>
                  
                <Header
                  headerStyle="gencont"
                  contactOpen={this.state.contactOpen}
                  action={() => this.toggleContact()}
                  {...this.props }
                />

                <section className="gencont-hero pad-top pad-bot-small">
                    <div className="row expanded">
                        <div className="small-12 medium-10 medium-push-1 large-push-3 large-6 columns">
                            <div className="herogencont-title">
                                { article.topline && <em>{article.topline}</em> }
                                <h1 className="dark">{article.title}</h1>
                                { article.bottomline && <em className="bottom-line">{article.bottomline}</em> }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="textblock bg-white textblock-case-studies">
                    {/* Content */}
                    <div className="row expanded">
                        <div className="small-12 medium-push-1 medium-10 large-push-2 large-8 xlarge-push-3 xlarge-6 columns pad-bot">
                             <div className="textblock-content pad-bot-small textblock-main-text"
                                    dangerouslySetInnerHTML={{__html: article.html}}>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="textblock bg-white">
                    {/* Content */}
                    <div className="row expanded collapse">
                        <div className="small-12 columns end">
                           <hr/>
                        </div>

                        <div className="small-12 medium-push-1 medium-10 large-push-3 large-6 columns end pad-top-small pad-bot-small">
                           <Link to={backLink} className="button dark go-left"><FormattedMessage id="back_to_case_studies" tagName="div"/><span></span></Link>
                        </div>
                     </div>
                </section>
                <Footer />
                </div>
            </PageTransition>  
        );

    };

}

export default SingleCaseStudy